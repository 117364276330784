import { MEVETH_CONTRACT } from '@/constants/addresses';
import { FAQContainer } from '@/layout/Footer';
import { colors, device } from '@/styles/common';
import { captureException } from '@sentry/nextjs';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import styled from 'styled-components';
import { formatEther } from 'viem';
import { useContractRead } from 'wagmi';
import MevEthAbi from '../../../MevEthAbi';
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

export const SectionFAQ = ({
  width,
  questions,
}: {
  width: string;
  questions: { title: string; answer: string }[];
}) => {
  return (
    <div style={{ width, marginTop: '20px' }}>
      <h1>FAQ</h1>
      <Accordion allowZeroExpanded>
        {questions.map((question, index) => (
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>{question.title}</AccordionItemButton>
            </AccordionItemHeading>

            <AccordionItemPanel>{question.answer}</AccordionItemPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  );
};
function AvailableToday() {
  const [totalStaked, setTotalStaked] = useState<string>('0');
  const { data: totalAssets, error } = useContractRead({
    address: MEVETH_CONTRACT,
    abi: MevEthAbi,
    functionName: 'totalAssets',
  });
  useEffect(() => {
    try {
      if (totalAssets) {
        const totalStakedData: string = formatEther(totalAssets as unknown as bigint);
        if (totalAssets) {
          setTotalStaked(parseFloat(totalStakedData.toString()).toFixed(0));
        }
      }
    } catch (error) {
      captureException(new Error('Failed to format totalAssets'), {});
    }
  }, [totalAssets]);
  return (
    <AvailableTodayContainer>
      <Title>
        Join the mevETH revolution today
        <br />
        {/** TODO */}
      </Title>
      <StakeNowButton href="/stake">
        <StakeNowText>mint mevETH</StakeNowText>
      </StakeNowButton>
      <Subtitle>
        {totalStaked}+ ETH and counting
        <br />
      </Subtitle>
      <SectionFAQ
        width="600px"
        questions={[
          {
            title: 'What is mevETH?',
            answer: `mevETH is an Ethereum Liquid Staking Token (LST)
                built on top of the ERC-4626 Vault Token standard.
                This means there is no rebasing involved, and no need for a 
                wrapped version of the token to be used within DeFi protocols 
                like Curve or Balancer. Simplified, mevETH provides you shares 
                to the tokens vault, which essentially grows in ETH from rewards.`,
          },
          {
            title: 'How does mevETH accrue rewards?',
            answer: `mevETH stakes the ETH in the protocol into ETH validators, 
              and captures rewards from the validators. There is a 10% fee for 
              staking with MEV Protocol, which goes to the protocol. Additionally,
               MEV Protocol will be capturing rewards through various avenues 
               involving MEV, and drive that to mevETH. Over time, mevETH will 
               grow proportionally with the ETH it has garnished from validator 
               rewards, and the future planned mechanisms to capture MEV.`,
          },
          {
            title: 'What is ERC-4626?',
            answer: `ERC-4626 is a standard to optimize and unify 
              the technical parameters of yield-bearing vaults. 
              It provides a standard API for tokenized yield-bearing vaults 
              that represent shares of a single underlying ERC-20 token. 
              ERC-4626 also outlines an optional extension for tokenized vaults 
              utilizing ERC-20, offering basic 
              functionality for depositing, withdrawing tokens and reading balances.`,
          },
          {
            title: 'Do I need to wrap mevETH?',
            answer: `No. As mevETH is a non-rebasing token thanks to the ERC-4626 vault 
              standard, which provides shares to the underlying in the vault, the amount 
              of mevETH will not change unless you deposit or withdraw mevETH. As the 
              vault grows, your shares proportional to the vault will grow in terms of ETH`,
          },
          {
            title: 'What is MEV Protocol’s Staking Fee?',
            answer: `MEV Protocol applies a 10% fee on staking rewards, which goes to the protocol.`,
          },
          {
            title: 'Where can I use mevETH?',
            answer: `mevETH can be used to LP with pools on Curve and Balancer. 
              Those LPs can additionally be 
              staked in Protocols like Convex, Yearn, and Aura.`,
          },
          {
            title: 'How do I skim rewards, withdraw, or exit mevETH?',
            answer: `All of this can be done on the Redemptions page.`,
          },
          {
            title: 'How do I know I am getting the best rate for mevETH?',
            answer: `Thanks to mevRouter, it is designed to consider minting price vs. 
              swap price from available LP pools. If it’s more efficient to swap ETH to 
              mevETH, it will utilize the LP pools. If not, it will directly mint mevETH. 
              It may also fulfill orders through both swapping and minting combined. 
              Additionally, mevRouter will also help prevent getting sandwiched to 
              ensure you the best rate when swapping mevETH.`,
          },
        ]}
      />
    </AvailableTodayContainer>
  );
}

const AvailableTodayContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-top: 72px;
  padding-bottom: 80px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 54px;
  line-height: 1.3;

  text-align: center;

  ${colors.whiteShine}

  @media ${device.mobileL} {
    font-size: 48px;
  }
  @media ${device.mobileM} {
    font-size: 42px;
  }
  @media ${device.mobileS} {
    font-size: 32px;
  }
`;

const Subtitle = styled.div`
  max-width: 364px;

  font-weight: 400;
  font-size: 19px;
  line-height: 1.5;
  text-align: center;

  ${colors.grayShine}

  @media ${device.mobileL} {
    max-width: unset;
    padding: 0 16px;
    font-size: 18px;
  }
  @media ${device.mobileM} {
    font-size: 16px;
  }
  @media ${device.mobileS} {
    font-size: 14px;
  }
`;

// Stake Now Button
const StakeNowButton = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 4px 12px 4px rgba(255, 255, 255, 0.25);

  padding: 16px 32px;

  &:hover {
    cursor: pointer;
    transform: scale(0.975);
  }
  transition: transform 0.225s ease-in-out;

  margin-top: 32px;
  margin-bottom: 24px;

  @media ${device.mobileL} {
    margin-top: 24px;
    margin-bottom: 16px;
  }

  @media ${device.mobileM} {
    margin-top: 20px;
    margin-bottom: 12px;
  }
`;

const StakeNowText = styled.div`
  font-weight: 600;
  font-size: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.03em;

  color: #000000;
`;

export default AvailableToday;
